import Swal from "sweetalert2";
export async function askConfirmation(
  message,
  confirmButtonText,
  cancelButtonText
) {
  let isConfirmed = false;
  await Swal.fire({
    icon: "question",
    title: "<small><b>Confirmation</b></small>",
    html: message,
    //showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      "<span style='font-size:14px;'>" +
      (confirmButtonText === "" || confirmButtonText === null
        ? ""
        : confirmButtonText) +
      " </span>",
    cancelButtonText:
      cancelButtonText === "" || cancelButtonText === null
        ? "No"
        : cancelButtonText,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#121212",
    //dangerMode: false,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
    }
  });
  return isConfirmed;
}
export async function showResponseMessage({ success, message }) {
  let isConfirmed = false;
  await Swal.fire({
    allowOutsideClick: false,
    icon: success ? "success" : "error",
    title: success ? "Success" : "Oops...",
    text:
      success === false && (message === null || message === "")
        ? "Unable to process the request."
        : message === null || message === ""
        ? "Requst processed successfully."
        : message,
    //html: "Sorry! We are unable to process you request.<br/>Please try after some times.Sorry! We are unable to process you request.<br/>Please try after some times.",
  }).then(async (result) => {
    if (result.isConfirmed) {
      isConfirmed = true;
    }
  });
  return isConfirmed;
}
export async function showUnderDevelopmentMessage() {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "warning",
    title: "Request Failed!",
    html: "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
export async function showSuccessMessage(message, title = "") {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "success",
    title,
    text: message ?? "Success",
  });
}
export async function showWarningMessage(message, title = "") {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "warning",
    title,
    text:
      message ??
      "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
export async function showErrorMessage(message) {
  await Swal.fire({
    allowOutsideClick: false,
    showCloseButton: true,
    icon: "error",
    title: "Oops...",
    text:
      message ??
      "Sorry! We are unable to process you request.<br/>Please try after some times.",
  });
}
