import { Center } from "devextreme-react/map";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { axiosPost } from "../../apis/useAxios";
import { LocalStorage } from "../../helper/appsession";
import { askConfirmation, showResponseMessage } from "../../helper/message";

export default function ChangePasswordComponent(props) {
  const [state, setState] = useState({
    pwdType: "password",
    confirmPwdType: "password",
    password: "",
    confirmPassword: "",
    errors: {
      password: "",
      confirmPassword: "",
    },
  });

  let isUseEffectRendered = false;
  useEffect(() => {
    let controller = new AbortController();
    try {
      if (isUseEffectRendered) {
        return;
      }
      return () => {
        controller.abort();
        isUseEffectRendered = true;
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleOnClosePasswordUpdateModal = () => {
    try {
      setState((prev) => ({
        ...prev,
        password: "",
        confirmPassword: "",
        errors: {
          password: "",
          confirmPassword: "",
        },
      }));
      props?.onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const isValid = () => {
    let isValid = true;
    try {
      let err = state.errors;
      err.password = "";
      err.confirmPassword = "";
      if (!state.password) {
        isValid = false;
        err.password = "Enter password";
      }
      if (!state.confirmPassword) {
        isValid = false;
        err.confirmPassword = "Please confirm password";
      }
      if (state.password && state.confirmPassword) {
        if (state.password.trim() !== state.confirmPassword.trim()) {
          err.confirmPassword = "Password not match, re-enter password";
          isValid = false;
        }
      }
      if (state.password && state.confirmPassword) {
        if (!isValidPasswordInput(state.password)) {
          isValid = false;
          err.password =
            "Please match all required paramenter for valid password";
        }
      }
      setState((prev) => ({ ...prev, errors: err }));
    } catch (error) {
      console.log(error);
    }
    return isValid;
  };
  const handleOnUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      if (isValid()) {
        let confirmationQuestion = `Are you sure want to update password`;
        let isConfirmed = await askConfirmation(
          confirmationQuestion,
          "Yes",
          "No"
        );
        if (!isConfirmed) return;
        setState((prev) => ({ ...prev, isSubmitted: true }));
        let response = await axiosPost(`user/${props.userId}/change-password`, {
          Password: state.password,
        });
        setState((prev) => ({ ...prev, isSubmitted: false }));
        isConfirmed = await showResponseMessage(response);
        if (isConfirmed) {
          if (response.success) {
            props.onClose();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isValidPasswordInput = (password) => {
    let isValid = false;
    try {
      var elementpwdlength = document.getElementById("pwdlength");
      var elementpwdlowercase = document.getElementById("pwdlowercase");
      var elementpwduppercase = document.getElementById("pwduppercase");
      var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
      var elementspecialcharacters =
        document.getElementById("specialcharacters");

      const renumbersymbolexp = /[0-9]/;
      const lowercaseexp = /[a-z]/;
      const uppercaseexp = /[A-Z]/;
      const specialcharacters = /[!@#$%^&*]/;

      var IsPwdnumbersymbol = renumbersymbolexp.test(password);
      var IsLowercase = lowercaseexp.test(password);
      var IsUppercase = uppercaseexp.test(password);
      var IsSpecialCharacters = specialcharacters.test(password);

      if (password.length > 8) {
        elementpwdlength.classList.remove("text-muted");
        elementpwdlength.classList.add("text-success");
      } else {
        elementpwdlength.classList.remove("text-success");
        elementpwdlength.classList.add("text-muted");
      }

      if (IsPwdnumbersymbol) {
        elementpwdnumbersymbol.classList.remove("text-muted");
        elementpwdnumbersymbol.classList.add("text-success");
      } else {
        elementpwdnumbersymbol.classList.remove("text-success");
        elementpwdnumbersymbol.classList.add("text-muted");
      }

      if (IsLowercase) {
        elementpwdlowercase.classList.remove("text-muted");
        elementpwdlowercase.classList.add("text-success");
      } else {
        elementpwdlowercase.classList.remove("text-success");
        elementpwdlowercase.classList.add("text-muted");
      }

      if (IsUppercase) {
        elementpwduppercase.classList.remove("text-muted");
        elementpwduppercase.classList.add("text-success");
      } else {
        elementpwduppercase.classList.remove("text-success");
        elementpwduppercase.classList.add("text-muted");
      }

      if (IsSpecialCharacters) {
        elementspecialcharacters.classList.remove("text-muted");
        elementspecialcharacters.classList.add("text-success");
      } else {
        elementspecialcharacters.classList.remove("text-success");
        elementspecialcharacters.classList.add("text-muted");
      }

      if (password === "") {
        elementpwdlength.classList.remove("text-success");
        elementpwdlength.classList.add("text-muted");
        elementpwdnumbersymbol.classList.remove("text-success");
        elementpwdnumbersymbol.classList.add("text-muted");
        elementpwdlowercase.classList.remove("text-success");
        elementpwdlowercase.classList.add("text-muted");
        elementpwduppercase.classList.remove("text-success");
        elementpwduppercase.classList.add("text-muted");
        elementspecialcharacters.classList.remove("text-success");
        elementspecialcharacters.classList.add("text-muted");
      }

      if (
        password.length > 8 &&
        IsPwdnumbersymbol === true &&
        IsLowercase === true &&
        IsUppercase === true &&
        IsPwdnumbersymbol === true &&
        IsSpecialCharacters === true
      ) {
        setState((prev) => ({ ...prev, isValidPassword: true }));
        isValid = true;
      } else {
        setState((prev) => ({ ...prev, isValidPassword: false }));
      }
    } catch (err) {
      setState((prev) => ({ ...prev, isValidPassword: false }));
      console.log(err);
    }
    return isValid;
  };

  const handleOnInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let err = state.errors;
    switch (name) {
      case "password":
        err.password = "";
        if (value === "" || value.length <= 60) {
          setState((prev) => ({
            ...prev,
            password: value,
          }));
          isValidPasswordInput(value);
        }
        break;
      case "confirmPassword":
        err.confirmPassword = "";
        if (value === "" || value.length <= 60) {
          setState((prev) => ({
            ...prev,
            confirmPassword: value,
          }));
          isValidPasswordInput(value);
        }
        break;

      default:
        break;
    }
  };
  const handleOnShowHide = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setState((prev) => ({
        ...prev,
        pwdType: state.pwdType === "input" ? "password" : "input",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnConfirmPassShowHide = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setState((prev) => ({
        ...prev,
        confirmPwdType: state.confirmPwdType === "input" ? "password" : "input",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={true}
        className={"react-modal custom-modal-medium"}
        shouldCloseOnOverlayClick={false}
        contentLabel="Exampal Modal"
      >
        <div className="modal-header">
          <h6 className="modal-title">Change Password</h6>
          <button
            type="button"
            className="close"
            onClick={() => {
              handleOnClosePasswordUpdateModal();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="cont-header" style={{ textAlign: "center" }}>
            <h5>
              You forgot your password? Here you can easily retrieve a new
              password.
            </h5>
            <br />
            <h6>
              You are going to change the password for{" "}
              <span style={{ color: "red" }}> {props?.userName}</span> as user
              name.
            </h6>
            <br />
          </div>
          {/* password */}
          <div>
            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
              <div className="row">
                {/* password */}
                <div className="col-6 col-sm-6 col-md-6 col-xl-6">
                  <div className="form-group">
                    <label>
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-1">
                      <input
                        type={state.pwdType}
                        className="form-control form-control-sm"
                        name="password"
                        placeholder="Enter password"
                        value={state.password}
                        onChange={(e) => {
                          handleOnInputChange(e);
                        }}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text p-0 pl-2 pr-2">
                          <a
                            className="text-dark"
                            href="#"
                            onClick={(e) => {
                              handleOnShowHide(e);
                            }}
                          >
                            <i
                              className={
                                state.pwdType === "input"
                                  ? "fas fa-eye"
                                  : "fas fa-eye-slash"
                              }
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {state.errors.password.length > 0 && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "small" }}
                      >
                        {state.errors.password}
                      </span>
                    )}
                  </div>
                  <div className="form-group"></div>
                </div>
                {/* confirmPassword */}
                <div className="col-6 col-sm-6 col-md-6 col-xl-6">
                  <div className="form-group">
                    <label>
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-1">
                      <input
                        type={state.confirmPwdType}
                        className="form-control form-control-sm"
                        name="confirmPassword"
                        placeholder="Enter password"
                        value={state.confirmPassword}
                        onChange={(e) => {
                          handleOnInputChange(e);
                        }}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text p-0 pl-2 pr-2">
                          <a
                            href="#"
                            className="text-dark"
                            onClick={(e) => {
                              handleOnConfirmPassShowHide(e);
                            }}
                          >
                            <i
                              className={
                                state.confirmPwdType === "input"
                                  ? "fas fa-eye"
                                  : "fas fa-eye-slash"
                              }
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {state.errors.confirmPassword.length > 0 && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "small" }}
                      >
                        {state.errors.confirmPassword}
                      </span>
                    )}
                  </div>
                  <div className="form-group"></div>
                </div>
              </div>
            </div>
          </div>
          {/* errors */}
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
              <div className="form-group">
                <p className="mb-0">
                  <small id="pwdlength" className="text-muted">
                    <i className="fas fa-check-circle"></i> At least 8
                    characters long
                  </small>
                </p>
                <p className="mb-0">
                  <small id="pwdlowercase" className="text-muted">
                    <i className="fas fa-check-circle"></i> One lowercase
                    character
                  </small>
                </p>
                <p className="mb-0">
                  <small id="pwduppercase" className="text-muted">
                    <i className="fas fa-check-circle"></i> One uppercase
                    character
                  </small>
                </p>
                <p className="mb-0">
                  <small id="pwdnumbersymbol" className="text-muted">
                    <i className="fas fa-check-circle"></i> One number, symbol,
                    or whitespace character
                  </small>
                </p>
                <p className="mb-0">
                  <small id="specialcharacters" className="text-muted">
                    <i className="fas fa-check-circle"></i> One special
                    character
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={(e) => {
              handleOnUpdatePassword(e);
            }}
          >
            <span title="Processing">
              <i
                className={`fas fa-${
                  state.isSubmitted ? "spinner fa-spin" : "check"
                }`}
              ></i>
              &nbsp;&nbsp; Update
            </span>
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            title="Close"
            onClick={() => {
              handleOnClosePasswordUpdateModal();
            }}
          >
            <i className="fas fa-times"></i> Close
          </button>
        </div>
      </Modal>
    </>
  );
}
