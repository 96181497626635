import React, { Component, Fragment } from "react";
import { TopBarComponent } from "./TopBarComponent";
import { SideBarComponent } from "./SideBarComponent";

export class AuthorisedLayout extends Component {
  static displayName = AuthorisedLayout.name;

  constructor(props) {
    super(props);
    //{this.addLibrary("https://app7.ameyoemerge.in:7443/ameyowebaccess/toolbar/js/ameyo-integration-v4_13.js")}
    //ameyo.integration.doLogin("sharadshitole","Sharad@2024","");
  }

  // addLibrary = (urlOfTheLibrary) => {
  //   const script = document.createElement("script");
  //   script.src = urlOfTheLibrary;
  //   script.async = true;
  //   script.type = "text/jsx";
  //   document.body.appendChild(script);
  // };

  async componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://app7.ameyoemerge.in:7443/ameyowebaccess/toolbar/js/ameyo-integration-v4_13.js";
    // script.async = true;
    // script.onload = () => this.scriptLoaded();  
    // document.body.appendChild(script);
    
    // const importedModule = await import("https://app7.ameyoemerge.in:7443/ameyowebaccess/toolbar/js/ameyo-integration-v4_13.js");
    // const importedModule1 = await import("https://zenplus.utwiz.com/ameyo_toolbar/dist/js/ameyo-integration-custom1.js");
  }

  // scriptLoaded() {
  //   //window.ameyo.integration();
  //   window.ameyo.integration.doLogin("sharadshitole","Sharad@2024","");
  // }

  render() {
    return (
      <Fragment>
        <div className="wrapper">          
          <TopBarComponent />
          <SideBarComponent />
          <div className="content-wrapper">{this.props.children}</div>
        </div>
      </Fragment>
    );
  }
}