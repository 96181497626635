import axios from "axios";
import { useState } from "react";
import axiosApi from "./axiosInstance";
import { axiosErrorHandler } from "./axiosErrorHandler";
export async function axiosPost(url, params = null) {
  const controllerRef = new AbortController();
  return await axiosApi
    .post(url, params, {
      signal: controllerRef.signal,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw axiosErrorHandler(err);
    })
    .finally(() => {
      controllerRef.abort();
    });
}
export async function axiosGet(url, params = null) {
  const controllerRef = new AbortController();
  return await axiosApi
    .request({
      url,
      params,
      signal: controllerRef.signal,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw axiosErrorHandler(err);
    })
    .finally(() => {
      controllerRef.abort();
    });
}
export async function axiosPut(url, params = null) {
  const controllerRef = new AbortController();
  return await axiosApi
    .request({
      url,
      method: "put",
      params,
      signal: controllerRef.signal,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw axiosErrorHandler(err);
    })
    .finally(() => {
      controllerRef.abort();
    });
}
export async function axiosPostWithQueryParamAndBody(
  url,
  params = null,
  body = null
) {
  const controllerRef = new AbortController();
  return await axiosApi
    .request({
      url,
      params,
      method: "post",
      data: body,
      signal: controllerRef.signal,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw axiosErrorHandler(err);
    })
    .finally(() => {
      controllerRef.abort();
    });
}

export async function axiosPostFormData(url, files = null) {
  const controllerRef = new AbortController();
  return await axiosApi
    .post(url, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: controllerRef.signal,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw axiosErrorHandler(err);
    })
    .finally(() => {
      controllerRef.abort();
    });
}

export function useAxiosApi() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const controllerRef = new AbortController();
  const CancelToken = axios.CancelToken;
  let cancel;

  const post = async (url, params = null) => {
    setLoading(true);
    await axiosApi
      .post(url, params, {
        signal: controllerRef.signal,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => setResponse(res.data))
      .catch((err) => {
        setError(axiosErrorHandler(err).message);
      })
      .finally(() => {
        setLoading(false);
        controllerRef.abort();
      });
  };
  const get = async (url, params = null) => {
    setLoading(true);
    await axiosApi
      .get(url, {
        params,
        signal: controllerRef.signal,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then((res) => setResponse(res.data))
      .catch((err) => {
        setError(axiosErrorHandler(err).message);
      })
      .finally(() => {
        setLoading(false);
        controllerRef.abort();
      });
  };

  return { loading, response, error, post, get, cancel };
}
