import axios from "axios";
const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_ZENPLUS_API_URL,
  headers: {
    //   //Authorization: `Bearer ${sessionStorage.getItem("session_accesstoken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  //timeout: 5000,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authorize_zenplus_api_token")
      ? localStorage.getItem("authorize_zenplus_api_token")
      : null;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      let token = await refreshAccessToken();
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
async function refreshAccessToken() {
  const request = {
    SecretKey: process.env.REACT_APP_ZENPLUS_API_SECRET_KEY,
  };
  var response = await axiosApi
    .post("/authorize/token", request, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res);
  if (response.status === 200) {
    localStorage.removeItem("authorize_zenplus_api_token");
    localStorage.setItem("authorize_zenplus_api_token", response.data);
    return response.data;
  }
  return null;
}
export default axiosApi;
