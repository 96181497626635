import React, { Component, Fragment } from "react";
import { axiosPost } from "../apis/useAxios";
import axios from "axios";
import {
  ClearSessionItems,
  LocalStorage,
  getCurrentLocation,
} from "../helper/appsession";
import { Icon } from "@iconify/react";
import { LoginAssetType } from "../helper/fixcode";
import { LogoutLoader } from "./Shared/PageLoader";

export class SideBarComponent extends Component {
  static displayName = SideBarComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      userFeatureSetup: JSON.parse(LocalStorage.UserFeatureSetup),
      roleSetup: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    //ameyo.integration.doLogin("sharadshitole","Sharad@2024","");
    //console.log("UserFeatureSetup: " + LocalStorage.UserFeatureSetup);
    let menuHtmlItem = this.getMenuHtml(this.state.userFeatureSetup);
    this.setState({
      menuHtml: menuHtmlItem,
    });
  }

  getMenuHtml(menuFeature) {
    let menuHtmlItem = [];
    menuFeature = JSON.parse(JSON.stringify(menuFeature));
    menuFeature?.forEach((menu) => {
      if (menu.subFeatureId === 0) {
        let childMenu = menuFeature.filter(
          (el) => el.subFeatureId === menu.featureId
        );
        const menuName = menu.displayName; //getVehicleLabelByIndustry(menu.menuName);
        if (childMenu.length > 0) {
          menuHtmlItem.push(
            <div className="card">
              <div
                className="card-header nav-link"
                id={"faqhead" + menu.featureId}
              >
                <a
                  href="#"
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target={"#faq" + menu.featureId}
                  aria-expanded="true"
                  aria-controls={"faq" + menu.featureId}
                >
                  <Icon icon={menu.iconName} className="nav-icon" />
                  <p>{menuName}</p>
                </a>
              </div>
              <div
                id={"faq" + menu.featureId}
                className="collapse"
                aria-labelledby={"faqhead" + menu.featureId}
                data-parent="#faq"
              >
                <div className="card-body p-0">
                  <ul key={menu.featureId} className="nav nav-treeview">
                    {this.subMenuHtml(childMenu)}
                  </ul>
                </div>
              </div>
            </div>
          );
        } else if (menuName === "Tracking") {
          menuHtmlItem.push(
            <div className="card">
              <div id={"faqhead" + menu.featureId}>
                <a href={menu.requestPath} className="nav-link">
                  <Icon icon={menu.iconName} className="nav-icon" />
                  <p>{menu.displayName}</p>
                </a>
              </div>
            </div>
          );
        } else if (menuName === "Ticket") {
          menuHtmlItem.push(
            <div className="card">
              <div id={"faqhead" + menu.featureId}>
                <a
                  href="https://ticketing.utwiz.com/"
                  target="_new"
                  className="nav-link"
                >
                  <Icon icon={menu.iconName} className="nav-icon" />
                  <p>{menu.displayName}</p>
                </a>
              </div>
            </div>
          );
        } else {
          menuHtmlItem.push(
            <div className="card">
              <div id={"faqhead" + menu.featureId}>
                <a href={menu.requestPath} className="nav-link">
                  <Icon icon={menu.iconName} className="nav-icon" />
                  <p>{menu.displayName}</p>
                </a>
              </div>
            </div>
          );
        }
      }
    });
    return menuHtmlItem;
  }

  subMenuHtml = (menuFeature) =>
    menuFeature.map((subchild) => (
      // <NavItem key={subchild.featureId}>
      //   <NavLink tag={Link} to={subchild.requestPath} title={subchild.menuName}>
      //     <Icon icon={subchild.iconName} className="nav-icon" />
      //     <p>{subchild.displayName}</p>
      //   </NavLink>
      // </NavItem>

      <div className="card">
        <div id={"faqhead" + subchild.featureId}>
          <a
            href={subchild.requestPath}
            className="nav-link"
            title={subchild.menuName}
          >
            <Icon icon={subchild.iconName} className="nav-icon" />
            <p>{subchild.displayName}</p>
          </a>
        </div>
      </div>
    ));

  handleOnLogout = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        isLoading: true,
      });
      let userId = LocalStorage.UserId;
      const { lat, lng } = await getCurrentLocation();
      let url = `/authenticate/user/${userId}/logout`;
      const response = await axiosPost(url, {
        SessionId: LocalStorage.SessionId,
        AssetTypeId: LoginAssetType.Web,
        Latitude: lat,
        Longitude: lng,
      });
      if (response.success) {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    ClearSessionItems();
    window.location.href = "/";
  };

  redirectToTracking = async () => {
    this.setState({ loadPanelVisible: true });
    try {
      const redirectdata = await axios
        .request(
          process.env.REACT_APP_CUSTOMER_API_URL +
            `RedirectUser/Insert/${LocalStorage.UserName}`
        )
        .then((res) => res.data)
        .catch((err) => {})
        .finally(() => {});

      this.setState({ loadPanelVisible: false });
      if (redirectdata.payload != null) {
        window.open(
          process.env.REACT_APP_CUSTOMER_SITE_URL +
            "?userguid=" +
            redirectdata.payload.token +
            "&source=ops",
          "_blank"
        );
      } else if (redirectdata.token != null) {
        window.open(
          process.env.REACT_APP_CUSTOMER_SITE_URL +
            "?userguid=" +
            redirectdata.token +
            "&source=ops",
          "_blank"
        );
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
  };

  // addLibrary = (urlOfTheLibrary) => {
  //   const script = document.createElement("script");
  //   script.src = urlOfTheLibrary;
  //   script.async = true;
  //   script.type = "text/jsx";
  //   document.body.appendChild(script);
  // }; 

  render() {
    return (
      <Fragment>
         {/* {addLibrary("https://app7.ameyoemerge.in:7443/ameyowebaccess/toolbar/js/ameyo-integration-v4_13.js")} */}
        <div className="main-sidebar">
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* <a href="/" className="brand-link">
              <img
                src="dist/img/utwiz-icon.png"
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">
                <img
                  src="dist/img/utiwiz-logo-light.png"
                  alt="AdminLTE Logo"
                  className="brand-image1"
                />
              </span>
            </a> */}
            <a href="/" className="brand-link">
              <img
                src="dist/img/zenplus-icon.png"
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">
                <img
                  src="dist/img/zenplus-fleet-maangement-logo-light.svg"
                  alt="AdminLTE Logo"
                  className="brand-image1"
                />
              </span>
            </a>
            {/* <div className="user-panel mt-2 pb-2 mb-2 d-flex">
              <div className="image">
                <img src="dist/img/avatar5.png" className="img-circle elevation-2" alt="User Image" />
              </div>
              <div className="info">
                <a href="#" className="d-block" style={{paddingLeft: "20px;"}}>{LocalStorage.UserName}!</a>
              </div>
            </div> */}
            <div className="sidebar">
              <div id="main">
                <div className="accordion" id="faq">
                  {this.state.menuHtml}
                  <div className="card">
                    <div id="faqhead100">
                      <a href="#" className="nav-link" onClick={(e) => this.handleOnLogout(e)}>
                        <i className="fas fa-sign-out-alt nav-icon"></i>
                        <p>Logout</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
        {this.state.isLoading && <LogoutLoader />}
      </Fragment>
    );
  }
}
